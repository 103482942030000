/* eslint-disable @nx/enforce-module-boundaries */
'use server';

import { initServerTranslation } from '@bts-web/utils-lokalise';
import { TGQLErrorResponseExtensionCode } from '../gqlNetworkFetch/types';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import {
  dataDogErrorHelperRoot,
  DatadogErrorLevels,
} from '@bts-web/utils-next-infrastructure';

export const translateErrorCodesOrReturnThemDirectly = async (
  errorCodes?: TGQLErrorResponseExtensionCode[],
): Promise<{
  translatedCodes: string[];
  genericTitle: string;
}> => {
  const { t } = initServerTranslation();

  const defaultGeneralError = t('inline_error_tranasaction_fail');

  if (errorCodes?.length === 0 || !errorCodes) {
    return {
      genericTitle: t('inline_error_tranasaction_fail'),
      translatedCodes: [],
    };
  }

  const translatedCodes = errorCodes.map((code) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const translatedCode = t(code as any);

    if (translatedCode !== code) {
      return translatedCode;
    } else {
      const errorValue = `API CONTENT ERROR: UNTRANSLATED KEY PROVIDED : ${code}`;

      if (isDevelopmentEnv()) {
        console.error('***************************');

        console.error(errorValue);

        console.error('***************************');
      }

      dataDogErrorHelperRoot(new Error(errorValue), {
        environment: process.env.NEXT_PUBLIC_DATADOG_ENV,
        severity: DatadogErrorLevels.WARNING,
      });

      return defaultGeneralError;
    }
  });

  return {
    translatedCodes: translatedCodes,
    genericTitle: defaultGeneralError,
  };
};
